<template>
  <div class="home">
    <navbar/>
    <div class="container">
      <div class="container__wrapper">
        <div class="users__filter">
          <div class="users__left">
            <div class="filter select1">
              <select class="md" name="" v-model="activeSchool" id="school">
                <option :value="null" :selected="activeSchool === null">Учреждения</option>
                <option :value="school.accountId" v-for="school in schools" :key="school.accountId">{{ school.name }}
                </option>
              </select>
            </div>
            <div class="filter select2">
              <select name="" v-model="activeClass" id="class">
                <option :value="null" :selected="activeClass === null">Классы</option>
                <option :value="cl.id" v-for="cl in schoolClass" :key="cl.id">{{ cl.fullName }}</option>
              </select>
            </div>
            <div class="filter select3">
              <select name="" v-model="activePay" id="pay">
                <option :value="null" :selected="activeClass === null">Все</option>
                <option :value="1">Платящие</option>
                <option :value="0">Неплатящие</option>
              </select>
            </div>
          </div>
          <div class="users__right">
            <div class="filter1 filter1__input">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0619 11.8375C13.1607 10.6856 13.8354 9.12542 13.8354 7.40771C13.8354 3.86263 10.9616 0.98877 7.41649 0.98877C3.87141 0.98877 0.997559 3.86263 0.997559 7.40771C0.997559 10.9528 3.87141 13.8266 7.41649 13.8266C9.24386 13.8266 10.8929 13.063 12.0619 11.8375ZM12.0619 11.8375L16.7748 16.5504" stroke="#E0E0E0" stroke-width="1.36259" stroke-linecap="round"/>
              </svg>
              <input type="text" v-model="search" placeholder="Поиск по имени">
            </div>
          </div>
        </div>
        <div class="users__table">
          <div class="users__head">
            <div class="users__item">Учреждение</div>
            <div class="users__item users__item_small">Класс</div>
            <div class="users__item users__item_big">Имя фамилия (id)</div>
            <div class="users__item">Номер тел.</div>
            <div class="users__item">E-mail</div>
            <div class="users__item">Последний вход</div>
          </div>
          <div class="users__rows">
            <div class="users__row" v-for="user in getUsers" :key="user.id" @click="getUser(user.id)">
              <div class="users__item">{{ user.educationObject }}</div>
              <div class="users__item users__item_small">{{ user.schoolClasses }}</div>
              <div class="users__item users__item_big">{{ user.fullName }}({{user.id}})</div>
              <div class="users__item">{{ user.phone }}</div>
              <div class="users__item">{{ user.email }}</div>
              <div class="users__item">{{ user.lastOnline }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Navbar from "@/components/NavBar/Navbar";

export default {
  name: 'Users',
  components: {Navbar},
  data() {
    return {
      schools: [],
      schoolClass: [],
      activeClass: null,
      activeSchool: null,
      activePay: null,
      search: null,
      users: []
    }
  },
  computed: {
    getEducation() {
      return this.$route.query.school
    },
    getClass() {
      return this.$route.query.class
    },
    getUsers() {
      let users = this.users
      if (this.activePay !== null) {
        users = users.filter(item => item.payStatus === this.activePay)
      }
      if (this.search) {
        console.log(users)
        users = users.filter(item => item.fullName.indexOf(this.search) !== -1
            || item.id.toString().indexOf(this.search) !== -1 || item.email.indexOf(this.search) !== -1)
      }
      return users
    }
  },
  methods: {
    getUser(user) {
      this.$router.push({name: 'UserItem', params: {id: user}})
    },
    async loadSchool() {
      const resp = await this.$axios.get('educations')
      if (resp.status === 200) {
        this.schools = resp.data
      }
    },
    async loadClass() {
      const resp = await this.$axios.get(`/school/${this.activeSchool}/class`)
      this.schoolClass = resp.data
    },
    async loadUsers() {
      this.users = []
      let link = `statistic/csv`
      if (this.activeClass) {
        link += `?class=${this.activeClass}`
      } else if (this.activeSchool) {
        link += `?school=${this.activeSchool}`
      }
      const resp = await this.$axios.get(link)
      this.users = resp.data
    }
  },
  watch: {
    activeSchool() {
      this.activeClass = null
      if (this.activeSchool !== null) {
        this.loadClass()
      }
      this.loadUsers()
    },
    activeClass() {
      this.loadUsers()
    },
    getEducation() {
      this.activeSchool = this.$route.query.school
    },
    getClass() {
      this.activeClass = this.$route.query.class
    }
  },
  mounted() {
    this.loadSchool()
    this.loadUsers()
  },
}
</script>
<style lang="scss" scoped>
.container {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%);

  &__wrapper {
    padding: 60px 0 0 0px;
    width: 828px;

  }
}

.home {
  //padding-bottom: 15vh;
}

.users {
  &__filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__table {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    padding: 19px 14px 45px;
    width: 100%;
    margin-top: 20px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .users__item {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #3B3F48;
      padding: 0 15px;
      white-space: nowrap;
    }
  }
  &__rows {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__row {
    padding: 14px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.044799);


    .users__item {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #3B3F48;
      padding-left: 15px;

    }
  }

  &__item {
    width: 100/6 + 0%;
    &_small {
      width: 10%;
    }
    &_big {
      width: 100/6 + 8%;
    }
  }
}

.filter {




  select {
    width: 124px;
    height: 40px !important;
    line-height: 40px !important;

    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
    background-repeat: no-repeat !important;
    background-position-x: 95% !important;
    background-position-y: 7px !important;

  }

  select, input {
    mix-blend-mode: normal;
    //opacity: 0.26;
    border: 1px solid #E2E5E9;
    border-radius: 8px;
    outline: none;
    height: 31px;
    line-height: 31px;
    padding: 0 10px;
  }

  input {
    width: 100%;
  }
}


.md{
  width: 164px !important;
}

.filter1{
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  overflow: hidden;
  width: 258px;
  height: 36px;

  svg{
    margin-left: 10px;
  }

  input{
    border: 0;
    background: transparent;
    margin-left: 10px;
    color: black;


    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    width: 210px;
    &::placeholder{
      color: #E0E0E0;
    }

    &:focus{
      outline: 0;
    }
  }
}


.select1{
  width: 164px;
  height: 40px;

  select{
    padding-left: 15px;
  }

}

.select2{
  width: 124px;
  height: 40px;
  margin-left: 12px;

  select{
    padding-left: 15px;
  }
}

.select3{
  width: 124px;
  height: 40px;
  margin-left: 12px;

  select{
    padding-left: 15px;
  }
}
</style>
